<template>
  <div class="mymock_record right_connent page my_center" ref="my_center">
    <div class="title">我的模考记录</div>
    <div class="connent_wrap">
      <div class="toobar_wrap">
        <div class="l">
          <el-select
            style="width: 160px"
            v-model="my_center_form.year"
            placeholder="请选择考研年份"
            @change="handleChangeYear"
          >
            <el-option
              v-for="item in prepare_year_list"
              :key="item.value"
              :label="item.prepare_year_name"
              :value="item.prepare_year"
            ></el-option>
          </el-select>
          <span style="padding-left: 10px; font-size: 14px; color: #606266"
            >已报名模考：{{ total }}轮</span
          >
        </div>
        <!-- <div class="r">
          <el-button round style="width: 120px" @click="handleCancel"
            >取消分析</el-button
          >
          <el-button
            type="primary"
            round
            style="width: 273px"
            :loading="loading_analysis"
            @click="handleAnalysis"
            >立即分析</el-button
          >
        </div> -->
      </div>
      <div class="mock_list_wrap">
        <template v-if="mock_list.length">
          <div class="mock_ul" style="min-height: 615px">
            <!-- <el-checkbox-group v-model="checkList"> -->
            <template v-for="(mock, mock_i) in mock_list">
              <!-- <el-checkbox
                  :label="mock.m_id"
                  :key="mock_i"
                  :disabled="!mock.checkflag"
                  @click.native="handleCheckboxClick"
                > -->
              <div
                class="mock_li_wrap"
                style="margin-top: 20px"
                @click="handleToDetail(mock)"
                :key="mock_i"
              >
                <div class="mock_li">
                  <div class="mock_title">
                    <b>{{ mock.mock_title }}</b>
                  </div>
                  <div class="mock_time">
                    <img src="@/assets/mine/home_time.png" alt="" />
                    <span>{{ mock.mock_exam }}</span>
                  </div>
                  <div class="mock_child">
                    <div class="mock_score">
                      <b>{{ mock.test_score }}</b
                      >分
                    </div>
                    <div class="child">
                      <div
                        class="child_li"
                        v-for="(child, child_i) in mock.mock_child"
                        :key="child_i"
                      >
                        <span class="child_alis" :title="child.child_alias">{{
                          child.child_alias
                        }}</span>
                        <div class="r">
                          <span class="time">{{
                            child.child_time ? child.child_time : ""
                          }}</span>
                          <span
                            class="status blue"
                            v-if="child.child_status == 1"
                            >待开考</span
                          >
                          <span
                            class="status red"
                            v-else-if="child.child_status == 2"
                            >考试中</span
                          >
                          <template v-else-if="child.child_status == 3">
                            <span class="status" v-if="child.record"
                              >{{ child.record.total_score }}分</span
                            >
                            <span class="status" v-else>无考试记录</span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="check_wrap"></div>
              </div>
              <!-- </el-checkbox> -->
            </template>
            <!-- </el-checkbox-group> -->
          </div>
          <div class="page_wrap">
            <el-pagination
              background
              :pager-count="5"
              :page-size="limit"
              layout="total, prev, pager, next, jumper"
              :total="total"
              @prev-click="prev_click"
              @next-click="next_click"
              @current-change="current_change"
              :hide-on-single-page="true"
              :current-page="page"
            >
            </el-pagination>
          </div>
        </template>
        <template v-else>
          <div class="empty_wrap">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { cookieGO, getCookie } from "@/config/cookie";
import { mapActions } from "vuex";
import tab from "@/components/tab.vue";
import api from "@/config/api";
import { tk_host } from "@/config/config.js";
let Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      mock_list: [],
      checkList: [],
      prepare_year_list: [
        {
          prepare_year: "0",
          prepare_year_name: "全部",
        },
      ],
      my_center_form: {
        year: "",
      },
      loading_analysis: false,
      page: 1,
      limit: 3,
      total: 0,
    };
  },
  created() {
    let current_year = new Date().getFullYear() + "";
    this.my_center_form.year = current_year;
    this.get_personal_data();
    this.get_mock_data();
  },
  methods: {
    async get_personal_data() {
      try {
        const res = await api.personal_data();
        if (res.code == 0) {
          let { prepare_year_list, oneself } = res.result;
          this.prepare_year_list =
            this.prepare_year_list.concat(prepare_year_list);
        } else {
        }
      } catch (e) {
        // console.log(e);
      }
    },
    async get_mock_data() {
      const res = await api.mymock_data({
        page: this.page,
        limit: this.limit,
        year: this.my_center_form.year,
      });
      if (res.code == 0) {
        let data = res.result;
        this.mock_list = data.data;
        this.mock_list.forEach((item) => {
          // 是否全部提交试卷
          let check_flag = false;
          let test_score = 0;
          item.mock_child.forEach((c) => {
            if (c.record) {
              test_score += c.record.total_score;
              check_flag = true;
            }
          });
          item["checkflag"] = check_flag;
          item["test_score"] = test_score;
        });
        this.total = data.count;
      } else {
        this.$message(res.message);
      }
    },
    handleChangeYear() {
      this.page = 1;
      this.get_mock_data(this.my_center_form.year);
    },
    prev_click(e) {
      this.page = e;
      this.get_mock_data();
    },
    next_click(e) {
      this.page = e;
      this.get_mock_data();
    },
    current_change(e) {
      this.page = e;
      this.get_mock_data();
    },
    handleCancel() {
      if (this.checkList.length > 0) {
        this.checkList = [];
        this.loading_analysis = false;
      } else {
        this.$message({
          message: "请先选择模考~",
          type: "warning",
        });
      }
    },
    handleAnalysis() {
      if (this.checkList.length > 0) {
        let param = Base64.encode(JSON.stringify(this.checkList));
        var aLink = document.createElement("a");
        aLink.target = "blank";
        aLink.href = `${tk_host}analysis_result/${param}`;
        aLink.click();
      } else {
        this.$message({
          message: "请先选择模考~",
          type: "warning",
        });
      }
    },
    handleCheckboxClick(event) {
      event.stopPropagation();
    },
    handleToDetail(mock) {
      var aLink = document.createElement("a");
      aLink.target = "blank";
      aLink.href = `${tk_host}mock_intro/${mock.m_id}`;
      aLink.click();
    },
  },
};
</script>
<style>
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266 !important;
}
.el-checkbox__inner {
  width: 30px;
  height: 30px;
}
.el-checkbox__inner::after {
  zoom: 2;
  left: 5px;
}
.el-checkbox .el-checkbox__input {
  order: 1; /* 将输入部分（包括选中框）放在文本之后 */
  margin-right: 0; /* 移除默认右边距，可根据需要调整 */
  margin-left: 28px; /* 添加适当的左边距 */
}

.el-checkbox .el-checkbox__label {
  order: 0; /* 文本放在前面 */
  flex: 1;
  padding-left: 0;
}
.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #606266;
  cursor: pointer;
}
</style>
<style lang="less" scoped>
.right_connent {
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  background-color: #fff;
  text-align: left;
  .title {
    font-size: 20px;

    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 30px;
  }
  .connent_wrap {
    .mock_list_wrap {
      height: 100%;
      box-sizing: border-box;
      cursor: pointer;
      .mock_ul {
        margin-top: 10px;
      }
      .mock_ul:first-child {
        margin-top: 0;
      }
      .el-checkbox {
        margin-top: 30px;
        display: flex;
        align-items: center;
        margin-right: 0;
      }

      .mock_li {
        background: #ffffff;
        border-radius: 8px;
        padding: 20px 30px;
        box-sizing: border-box;
        border: 1px solid #dcdfe6;
        .mock_time {
          font-size: 14px;
          color: #198cff;
          display: flex;
          align-items: center;
          margin: 10px 0;
          img {
            margin-right: 8px;
          }
        }
        .mock_child {
          background: linear-gradient(
            180deg,
            #f7f9fa 0%,
            rgba(247, 249, 250, 0) 100%
          );
          border-radius: 8px 8px 8px 8px;
          padding: 0 10px;
          .mock_score {
            font-size: 14px;
            color: #151515;
            padding: 10px 0;
            border-bottom: 1px solid #e1e1e1;
          }
          .child {
            padding-top: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .child_li {
              width: 45%;
              padding-bottom: 10px;
              font-size: 14px;
              display: flex;
              .child_alis {
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .r {
                // width: 170px;
                display: flex;
                .time {
                  color: #666666;
                  padding-left: 22px;
                }
                .status {
                  flex: 1;
                  color: #666666;
                  padding-left: 15px;
                }
                .status.blue {
                  color: #198cff;
                }
                .status.red {
                  color: #ec652b;
                }
              }
            }
            .child_li:nth-child(even) {
              padding-left: 50px;
              box-sizing: border-box;
            }
            .child_li:nth-child(odd) {
              padding-right: 50px;
              box-sizing: border-box;
            }
          }
        }
        .mock_bot {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          .count {
            font-size: 14px;
            color: #9e9e9e;
          }
          .btn .to_exam {
            display: inline-block;
            width: 180px;
            height: 30px;
            background: #198cff;
            border-radius: 100px 100px 100px 100px;
            line-height: 30px;
            font-size: 14px;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
}
.toobar_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mymock_record {
  position: relative;
}
.page_wrap {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
