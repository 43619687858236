<template>
  <div class="tab_wrap">
    <div class="tab_">
      <div :class="tab_index == index ? 'on li' : 'li'" v-for="(item, index) in tab_list" :key="index"   @click="change_tab(index)">
        {{ item.column_title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab_index: 0,
    };
  },
  name: "tab",
  props: {
    tab_list: Array,
    column_id: String,
  },
  mounted() {
    if (this.column_id || this.column_id == 0) {
      this.tab_list.forEach((item, index) => {
        if (item.id == this.column_id) {
          this.tab_index = index;
        }
      });
    }
  },
  methods: {
    change_tab(index) {
      this.tab_index = index;
      let id = this.tab_list[index].id;
      this.$emit("tab_index", id+'');
    },
  },
};
</script>
<style>
</style>

<style lang="less" scoped>
.tab_wrap {
  .tab_ {
    display: flex;
    align-items: center;
    .li.on {
      font-weight: bold;
    }
    .li.on::after {
      content: "";
      display: block;
      width: 22px;
      height: 4px;
      background: #479dff;
      border-radius: 2px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .li {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      margin-right: 90px;
      cursor: pointer;
    }
    .li:last-of-type {
      margin: 0;
    }
  }
}
</style>